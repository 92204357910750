var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        "is-active": _vm.isActive,
        "on-close": _vm.onClose,
        "show-cancel-button": "",
        "submit-button": { content: "Klaar", onClick: _vm.submit },
      },
    },
    [
      _c("div", { staticClass: "flex flex-col" }, [
        _c("h1", { staticClass: "font-poppins font-bold text-lg" }, [
          _vm._v("Adres wijzigen"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 space-y-5 mb-3" }, [
          _c(
            "div",
            { staticClass: "flex w-full" },
            [
              _c("text-input", {
                attrs: {
                  label: "Staatnaam",
                  name: "street",
                  placeholder: "Dorpstraat",
                  value: _vm.street,
                  error: _vm.errors.street,
                  required: true,
                  type: "text",
                  "on-change": function (value) {
                    return (this$1.street = value)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("text-input", {
                attrs: {
                  label: "Nummer",
                  name: "number",
                  placeholder: "20",
                  value: _vm.houseNumber,
                  error: _vm.errors.houseNumber,
                  required: true,
                  type: "number",
                  "on-change": function (value) {
                    return (this$1.houseNumber = value)
                  },
                },
              }),
              _vm._v(" "),
              _c("text-input", {
                attrs: {
                  label: "Toevoeging",
                  name: "addition",
                  placeholder: "a",
                  value: _vm.addition,
                  error: _vm.errors.addition,
                  required: true,
                  type: "text",
                  "on-change": function (value) {
                    return (this$1.addition = value)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("text-input", {
                attrs: {
                  label: "Postcode",
                  name: "postalCode",
                  placeholder: "3800",
                  value: _vm.postalCode,
                  error: _vm.errors.postalCode,
                  required: true,
                  type: "number",
                  "on-change": function (value) {
                    return (this$1.postalCode = value)
                  },
                },
              }),
              _vm._v(" "),
              _c("text-input", {
                attrs: {
                  label: "Gemeente",
                  name: "city",
                  placeholder: "Sint-Truiden",
                  value: _vm.city,
                  error: _vm.errors.city,
                  required: true,
                  type: "text",
                  "on-change": function (value) {
                    return (this$1.city = value)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }