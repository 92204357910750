<template>
  <div v-if="product != null" class="fixed z-10 inset-0 overflow-y-auto"
       aria-labelledby="modal-title"
       role="dialog" aria-modal="true" @click="close">
    <div class="flex items-end justify-center min-h-screen pt-4 sm:px-4 px-2 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
          @click.stop
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-11/12 w-full font-poppins">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <h1 class="text-lg sm:text-xl font-semibold">Toevoegen aan winkelmandje</h1>

          <div class="flex space-x-1 mt-2 text-sm mb-6">
            <div
                v-if="product.package"
                class="flex items-center justify-center bg-secondary sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8">
              <span>{{ product.name }}</span>
            </div>

            <div
                v-if="product.package"
                class="flex items-center justify-center bg-primary-dark sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8">
              <span>{{ product.contentAmount }} potjes</span>
            </div>

            <div
                v-if="!product.package"
                class="flex items-center justify-center bg-primary-dark sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8">
              <span>{{ product.weight }} g</span>
            </div>

            <div
                class="flex items-center justify-center bg-primary-dark sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8">
              <span>€ {{ productPrice }}</span>
            </div>
          </div>

          <div class="w-full px-5 mt-5">
            <ul class="list-disc mb-3 h-4/5 space-y-3">
              <li v-for="content in product.contents">{{ content }}</li>
            </ul>
          </div>

          <div class="flex items-center mt-5 space-x-5">
            <p class="font-semibold text-center md:w-2/12 w-3/12">€ {{ total }}</p>
            <div class="md:w-2/12 w-3/12">
              <amount-selector
                  :amount="amount"
                  :on-increment="incrementAmount"
                  :on-decrement="decrementAmount"
              ></amount-selector>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-secondary-hover sm:ml-3 sm:w-auto sm:text-sm select-none"
              @click="addToCart">
            Toevoegen
          </button>
          <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm select-none"
              @click="close">
            Annuleren
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {parseNumber, parseToCommaSeparated} from "../../helpers/StringHelper";
import {mapState} from "vuex";
import {ShoppingCartService} from "../../services/ShoppingCartService";
import store from "../../store";
import {DANGER} from "../../constants/Variants";
import {captureException} from "@sentry/vue";

export default {
  name: "AddToCartModal",
  props: {},
  data() {
    return {
      amount: 1,
      shoppingCartService: new ShoppingCartService(),
    }
  },
  methods: {
    incrementAmount() {
      this.amount++;
    },
    decrementAmount() {
      if (this.amount > 1) this.amount--;
    },
    close() {
      this.$store.commit('SET_CURRENT_PRODUCT', null);
      this.amount = 1;
    },
    openInfoModal() {
      store.commit('SET_INFO', {
        active: true,
        title: 'Wil je je inloggen?',
        message: 'Je kan pas producten toevoegen aan jouw winkelmandje wanneer je ingelogd ben. Wil je je inloggen?',
        onActionClick: () => window.location.href = '/login',
      });
    },
    addToCart() {
      if (this.user && !this.user.id) {
        this.close();

        this.openInfoModal();
        return;
      }

      this.shoppingCartService.updateProductInCart(this.product, this.amount)
          .then(() => {
            this.close();
          })
          .catch(error => {
            this.close();
            captureException(error);

            store.commit('SET_ERROR', {
              active: true,
              message: 'Er is iets misgegaan tijdens het toevoegen van een product aan jouw winkelwagentje.',
              variant: DANGER
            });
          });
    },
    getAmountCurrentlyInCart(product) {
      let cartItem = this.cart.find(c => c.product.id === product.id);

      if (cartItem) {
        return cartItem.amount;
      } else {
        return 1;
      }
    }
  },
  computed: {
    product() {
      let currentProduct = this.$store.state.currentProduct;
      if (!currentProduct) return null;
      else currentProduct = Object.assign({}, this.$store.state.currentProduct);

      currentProduct.price = parseNumber(currentProduct.price);

      this.amount = this.getAmountCurrentlyInCart(currentProduct);

      return currentProduct;
    },
    total() {
      if (!this.product) return 0;

      return parseToCommaSeparated(this.product.price * this.amount, 2);
    },
    productPrice() {
      if (!this.product) return 0;
      return parseToCommaSeparated(this.product.price, 2);
    },
    ...mapState({
      user: state => state.user,
      cart: state => state.cart,
    })
  },
}
</script>

<style scoped>

</style>
