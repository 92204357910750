<template>
  <div class="flex flex-col h-56 relative items-center rounded-xl bg-white py-5 px-3 mr-5 shadow-md font-poppins">
    <div
        class="flex justify-center items-center bg-primary-dark text-white rounded-bl-xl rounded-tr-xl text-center h-8 w-1/4 xl:w-1/3 absolute top-0 right-0">
      <p>€ {{ parsedProduct.price }}</p>
    </div>

    <div class="mb-3 h-4/5">
      <ul v-if="parsedProduct.ingredients" class="list-disc">
        <li v-for="ingredient in parsedProduct.ingredients">{{ ingredient }}</li>
      </ul>

      <h1 v-if="parsedProduct.contents.length" class="mt-5">{{ parsedProduct.name }}</h1>
    </div>

    <div class="flex items-center justify-center h-12 w-3/4 2xl:w-2/3 px-3 mx-5 rounded-lg
     bg-secondary hover:bg-secondary-hover cursor-pointer text-white" @click="updateCurrentProduct">
      <span class="iconify h-5 w-5 mr-3" data-icon="ant-design:shopping-cart-outlined" data-inline="false"></span>
      <p class="text-sm">Toevoegen</p>
    </div>
  </div>
</template>

<script>
import {capitalize, parseToCommaSeparated} from "../../helpers/StringHelper";
import {mapMutations, mapState} from 'vuex';

export default {
  name: "ProductCard",
  props: {
    product: Object,
  },
  data() {
    return {
      parsedProduct: this.product,
    }
  },
  methods: {
    ...mapMutations([
      'SET_CURRENT_PRODUCT'
    ]),
    updateCurrentProduct() {
      this.SET_CURRENT_PRODUCT(this.parsedProduct);
    },
  },
  computed: {
    ...mapState({
      currentProduct: state => state.currentProduct
    })
  },
  mounted() {
    let product = this.product;

    if (product.package) {
      product.contents = product.contents.split(',').map(contentItem => contentItem.trim());
    } else {
      product.ingredients = product.name.split('-').map(ingredient => capitalize(ingredient.trim()));
    }

    product.price = parseToCommaSeparated(product.price, 2);

    this.parsedProduct = product;
  }
}
</script>

<style scoped>

</style>
