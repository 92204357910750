var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        "submit-button": { content: "Oké!", onClick: _vm.info.onActionClick },
        "show-cancel-button": "",
        "is-active": _vm.info.active,
        "on-close": _vm.onClose,
      },
    },
    [
      _c("div", { staticClass: "flex space-x-3 items-center h-1/2" }, [
        _c("h1", { staticClass: "text-xl font-semibold" }, [
          _vm._v(_vm._s(_vm.info.title)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-5 w-full" }, [
        _c("p", [_vm._v(_vm._s(_vm.info.message))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }