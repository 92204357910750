var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col bg-white rounded-md shadow-xl w-full lg:w-1/2 md:p-10 py-10",
    },
    [
      _c("div", { staticClass: "grid justify-items-center items-center" }, [
        _c("img", {
          staticClass: "h-72 w-auto rounded-md",
          attrs: { src: _vm.image, alt: _vm.title },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "grid justify-items-center items-center" }, [
        _c("div", { staticClass: "w-2/3 mt-5" }, [
          _c("div", { staticClass: "text-center font-amatic mb-5" }, [
            _c("h1", { staticClass: "text-4xl" }, [_vm._v(_vm._s(this.title))]),
          ]),
          _vm._v(" "),
          _c("p", [_vm._t("default")], 2),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }