var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isActive
    ? _c(
        "div",
        {
          staticClass: "fixed z-10 inset-0 overflow-y-auto",
          attrs: {
            "aria-labelledby": "modal-title",
            role: "dialog",
            "aria-modal": "true",
          },
          on: { click: _vm.onClose },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0",
            },
            [
              _c("div", {
                staticClass:
                  "fixed inset-0 bg-black bg-opacity-50 transition-opacity",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "hidden sm:inline-block sm:align-middle sm:h-screen",
                  attrs: { "aria-hidden": "true" },
                },
                [_vm._v("​")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full font-poppins",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" },
                    [_vm._t("default")],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse",
                    },
                    [
                      _vm.submitButton
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-secondary-hover sm:ml-3 sm:w-auto sm:text-sm",
                              attrs: { type: "button" },
                              on: { click: _vm.submitButton.onClick },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.submitButton.content) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCancelButton
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "mt-3 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                              attrs: { type: "button" },
                              on: { click: _vm.onClose },
                            },
                            [_vm._v("\n          Annuleren\n        ")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }