var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-auto mt-10 w-full lg:w-2/3 font-poppins" },
    [
      _c("user-info-modal", {
        attrs: {
          "is-active": _vm.order && _vm.userInfoModalIsActive,
          "on-close": function () {
            return (this$1.userInfoModalIsActive = false)
          },
          user: _vm.order ? _vm.order.user : null,
        },
      }),
      _vm._v(" "),
      _vm.order
        ? _c("div", { staticClass: "flex flex-row px-2 sm:px-6 space-x-2" }, [
            _c(
              "button",
              {
                staticClass:
                  "space-x-2 mt-3 w-2/12 h-10 inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-sm font-medium items-center text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto",
                attrs: { type: "button" },
                on: { click: _vm.back },
              },
              [
                _c("span", {
                  staticClass: "iconify font-bold h-5 w-5",
                  attrs: { "data-icon": "mdi:arrow-left" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "hidden sm:inline-block" }, [
                  _vm._v("Terug"),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.user.isAdmin
              ? _c(
                  "button",
                  {
                    staticClass:
                      "mt-3 inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-sm font-medium items-center text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto",
                    attrs: { type: "button" },
                    on: {
                      click: function () {
                        return (this$1.userInfoModalIsActive = true)
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "iconify font-bold h-5 w-5",
                      attrs: { "data-icon": "mdi:user" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-2" }, [
                      _c(
                        "span",
                        {
                          staticClass: "mr-1 font-light hidden sm:inline-block",
                        },
                        [_vm._v("Besteld door: ")]
                      ),
                      _vm._v(_vm._s(_vm.order.user.getFullName())),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          !_vm.order
            ? _c("orders-table", {
                attrs: {
                  orders: _vm.parsedOrders,
                  "on-order-click": _vm.setCurrentOrder,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.order
            ? _c("order-detail", { attrs: { order: _vm.order } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.order
            ? _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("pagination", {
                    attrs: {
                      "pagination-data": _vm.paginationData,
                      "set-page-number": _vm.getOrdersOfPage,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }