<template>
  <div class="mx-auto mt-10 w-full lg:w-2/3 font-poppins">
    <user-info-modal
        :is-active="order && userInfoModalIsActive"
        :on-close="() => this.userInfoModalIsActive = false"
        :user="order ? order.user : null"
    ></user-info-modal>

    <div v-if="order" class="flex flex-row px-2 sm:px-6 space-x-2">
      <button
          @click="back"
          type="button"
          class="space-x-2 mt-3 w-2/12 h-10 inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-sm font-medium items-center text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto">
        <span class="iconify font-bold h-5 w-5" data-icon="mdi:arrow-left"></span>
        <span class="hidden sm:inline-block">Terug</span>
      </button>

      <button
          v-if="user.isAdmin"
          @click="() => this.userInfoModalIsActive = true"
          type="button"
          class="mt-3 inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-sm font-medium items-center text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto">
        <span class="iconify font-bold h-5 w-5" data-icon="mdi:user"></span>
        <span class="ml-2"><span
            class="mr-1 font-light hidden sm:inline-block">Besteld door: </span>{{ order.user.getFullName() }}</span>
      </button>
    </div>
    <div class="mt-5">
      <orders-table v-if="!order" :orders="parsedOrders" :on-order-click="setCurrentOrder"></orders-table>
      <order-detail v-if="order" :order="order"></order-detail>

      <div v-if="!order" class="mt-3">
        <pagination
            :pagination-data="paginationData"
            :set-page-number="getOrdersOfPage"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import OrderDetail from "./OrderDetail";
import {Order} from "../../../models/Order";
import {OrderService} from "../../../services/OrderService";
import {captureEvent} from "@sentry/vue";
import {mapState} from "vuex";

export default {
  name: "OrderContent",
  components: {OrderDetail},
  props: {
    orders: Object,
  },
  data() {
    return {
      paginationData: null,
      order: null,
      parsedOrders: [],
      orderService: new OrderService(),
      userInfoModalIsActive: false,
    }
  },
  methods: {
    getOrdersOfPage(pageNumber) {
      this.orderService.get(pageNumber)
          .then(paginationData => {
            this.paginationData = paginationData;
            this.parsedOrders = this.parseOrders(paginationData);
          })
          .catch(error => {
            captureEvent(error);
          })
    },
    setCurrentOrder(order) {
      this.order = order;
    },
    back() {
      this.order = null;

      const orderIdInUrl = this.getOrderIdFromUrl();

      if (orderIdInUrl) {
        window.location.pathname = window.location.pathname.replace('/' + orderIdInUrl, '');
      }
    },
    parseOrders(orders) {
      return orders.data.map(orderData => {
        let order = new Order();
        order.parse(orderData);

        return order;
      })
    },
    getOrderIdFromUrl() {
      const orderId = parseInt(window.location.pathname
          .replaceAll('/', '')
          .replace('orders', '')
      );

      if (isNaN(orderId)) return null;

      return orderId;
    }
  },
  mounted() {
    this.parsedOrders = this.parseOrders(this.orders);
    this.paginationData = this.orders;

    const orderId = this.getOrderIdFromUrl();

    if (orderId) {
      this.order = this.parsedOrders.find(order => order.id === orderId);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
}
</script>

<style scoped>

</style>
