<template>
  <div class="container mx-auto flex flex-col font-poppins text-gray-800 text-md lg:text-lg mt-10 text-center">
    <h1 class="text-4xl lg:text-5xl font-amatic font-bold">{{ this.title }}</h1>
    <p class="mt-3">{{ this.subText }}</p>
  </div>
</template>

<script>
export default {
  name: "HeaderTitle",
  props: {
    title: String,
    subText: String,
  }
}
</script>

<style scoped>

</style>
