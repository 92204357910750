var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "flex bg-white rounded-xl hover:shadow-sm w-full xl:w-1/2 2xl:w-1/3 text-sm xl:text-base font-poppins px-5 py-3 border-2 mt-5 cursor-pointer " +
        _vm.getBorderColor(),
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "flex items-center justify-center w-1/5" }, [
        _c("span", {
          class: "iconify h-6 w-6 " + _vm.getIconColor(),
          attrs: { "data-icon": _vm.getIcon() },
        }),
      ]),
      _vm._v(" "),
      _vm.rowAContent && _vm.rowAContent !== ""
        ? _c("div", { staticClass: "w-4/5 text-center" }, [
            _c("span", { class: "block " + _vm.getFontWeight() }, [
              _vm._v(_vm._s(_vm.rowAContent)),
            ]),
            _vm._v(" "),
            _vm.rowBContent
              ? _c("span", { staticClass: "block" }, [
                  _vm._v(_vm._s(_vm.rowBContent)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.rowCContent
              ? _c("span", { staticClass: "block" }, [
                  _vm._v(_vm._s(_vm.rowCContent)),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.rowAContent
        ? _c("div", { staticClass: "w-4/5 text-center" }, [
            _c("span", [_vm._v(_vm._s(_vm.emptyContent))]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }