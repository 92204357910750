import { render, staticRenderFns } from "./TextInput.vue?vue&type=template&id=16824556&scoped=true&"
import script from "./TextInput.vue?vue&type=script&lang=js&"
export * from "./TextInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16824556",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jonas/Documents/Projects/docker-projects/mamavert/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16824556')) {
      api.createRecord('16824556', component.options)
    } else {
      api.reload('16824556', component.options)
    }
    module.hot.accept("./TextInput.vue?vue&type=template&id=16824556&scoped=true&", function () {
      api.rerender('16824556', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/forms/TextInput.vue"
export default component.exports