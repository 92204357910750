var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    { staticClass: "cursor-pointer", on: { click: _vm.onOrderClick } },
    [
      _c(
        "td",
        { staticClass: "px-6 py-4 whitespace-nowrap hidden sm:table-cell" },
        [
          _c("div", { staticClass: "flex items-center" }, [
            _c("div", { staticClass: "ml-4" }, [
              _c("div", { staticClass: "font-medium text-gray-900" }, [
                _vm._v(_vm._s(_vm.order.id)),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.order.user && _vm.user.isAdmin
        ? _c("td", { staticClass: "px-2 py-2" }, [
            _c("span", [_vm._v(_vm._s(_vm.order.user.getFullName()))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("td", { staticClass: "px-6 py-4 whitespace-nowrap" }, [
        _c("div", { staticClass: "text-gray-900" }, [
          _vm._v(_vm._s(_vm.order.date)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "px-6 py-4 whitespace-nowrap hidden sm:table-cell" },
        [
          _c("div", { staticClass: "text-gray-900" }, [
            _vm._v(_vm._s(_vm.order.getProductAmount())),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("td", { class: _vm.getPriceStyle() }, [
        _vm._v("€ " + _vm._s(_vm.order.total)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }