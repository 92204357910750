var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex flex-row flex-row-reverse items-center relative p-0.5 rounded-xl cursor-pointer",
        on: {
          click: function () {
            return _vm.onCartClick()
          },
        },
      },
      [
        _c("span", {
          staticClass:
            "iconify h-6 w-6 md:h-8 md:w-8 text-gray-500 hover:text-gray-400 duration-200",
          attrs: { "data-icon": "mdi:shopping-cart", "data-inline": "false" },
        }),
        _vm._v(" "),
        _vm.showPrice
          ? _c(
              "span",
              { staticClass: "mr-1 text-gray-700 text-sm font-poppins" },
              [_vm._v("€ " + _vm._s(_vm.totalPrice))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.user && _vm.user.id && _vm.amount
          ? _c(
              "span",
              {
                staticClass:
                  "rounded-full bg-secondary w-4 h-4 absolute top-0 right-0 text-white text-xs font-bold text-center select-none",
              },
              [_vm._v("\n          " + _vm._s(this.amount) + "\n    ")]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.showDropdown
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.onClickOutside,
                expression: "onClickOutside",
              },
            ],
            staticClass:
              "absolute top-15 right-0 px-4 py-2 bg-white rounded-sm shadow-md z-10 w-2/3 lg:w-1/2",
          },
          [
            _c(
              "h1",
              { staticClass: "font-poppins font-semibold text-md my-4" },
              [_vm._v("Winkelwagen")]
            ),
            _vm._v(" "),
            _vm._l(_vm.cart, function (cartItem) {
              return _c(
                "div",
                {
                  staticClass:
                    "flex cursor-default mb-3 w-full space-x-2 md:space-x-5",
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-sm text-gray-700 w-1/12 text-center" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(cartItem.amount + "x") +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-sm text-gray-700 w-10/12" }, [
                    _vm._v(
                      "\n        " + _vm._s(cartItem.product.name) + "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "font-bold text-sm w-2/12 text-center" },
                    [
                      _vm._v(
                        "\n        € " +
                          _vm._s(
                            _vm.parsePrice(
                              cartItem.amount * cartItem.product.price
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 mb-5" }, [_c("divider")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-row-reverse mb-5 text-sm" }, [
              _c(
                "div",
                { staticClass: "font-bold text-sm w-2/12 text-center ml-3" },
                [_vm._v("€ " + _vm._s(_vm.totalPrice))]
              ),
              _vm._v(" "),
              _c("span", {}, [_vm._v("Totaalbedrag: ")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex flex-row-reverse items-center mb-2" },
              [
                _c(
                  "span",
                  { staticClass: "w-1/4" },
                  [
                    _c("cta-button", {
                      attrs: {
                        content: "Bestellen",
                        variant: _vm.orderButtonVariant,
                        "on-click": _vm.toCartPage,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "text-sm text-blue-600 underline mr-3 cursor-pointer",
                    on: { click: _vm.toCartPage },
                  },
                  [_vm._v("Wijzig winkelmandje")]
                ),
              ]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }