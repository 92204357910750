var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("address-modal", {
        attrs: {
          "is-active": _vm.addressModalIsActive,
          "on-close": _vm.closeAddressModal,
          user: _vm.user,
        },
      }),
      _vm._v(" "),
      _c("address-info-item", {
        attrs: {
          "row-a-content":
            this.user && this.user.street
              ? this.user.street +
                " " +
                this.user.houseNumber +
                " " +
                this.user.addition
              : "",
          "row-b-content": this.user
            ? this.user.postalCode + " - " + this.user.city
            : "",
          "empty-content": "Adres toevoegen",
          "on-click": _vm.openAddressModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }