var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col h-56 relative items-center rounded-xl bg-white py-5 px-3 mr-5 shadow-md font-poppins",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex justify-center items-center bg-primary-dark text-white rounded-bl-xl rounded-tr-xl text-center h-8 w-1/4 xl:w-1/3 absolute top-0 right-0",
        },
        [_c("p", [_vm._v("€ " + _vm._s(_vm.parsedProduct.price))])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3 h-4/5" }, [
        _vm.parsedProduct.ingredients
          ? _c(
              "ul",
              { staticClass: "list-disc" },
              _vm._l(_vm.parsedProduct.ingredients, function (ingredient) {
                return _c("li", [_vm._v(_vm._s(ingredient))])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.parsedProduct.contents.length
          ? _c("h1", { staticClass: "mt-5" }, [
              _vm._v(_vm._s(_vm.parsedProduct.name)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center h-12 w-3/4 2xl:w-2/3 px-3 mx-5 rounded-lg\n   bg-secondary hover:bg-secondary-hover cursor-pointer text-white",
          on: { click: _vm.updateCurrentProduct },
        },
        [
          _c("span", {
            staticClass: "iconify h-5 w-5 mr-3",
            attrs: {
              "data-icon": "ant-design:shopping-cart-outlined",
              "data-inline": "false",
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [_vm._v("Toevoegen")]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }