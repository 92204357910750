var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("thead", { staticClass: "bg-gray-50" }, [
    _c("tr", { staticClass: "text-xs" }, [
      _c(
        "th",
        {
          staticClass:
            "px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell",
          attrs: { scope: "col" },
        },
        [_vm._v("\n    Volgnummer\n  ")]
      ),
      _vm._v(" "),
      _vm.user && _vm.user.isAdmin
        ? _c(
            "th",
            {
              staticClass:
                "px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider",
              attrs: { scope: "col" },
            },
            [_vm._v("\n    Gebruiker\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "th",
        {
          staticClass:
            "px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider",
          attrs: { scope: "col" },
        },
        [_vm._v("\n    Besteldatum\n  ")]
      ),
      _vm._v(" "),
      _c(
        "th",
        {
          staticClass:
            "px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell",
          attrs: { scope: "col" },
        },
        [_vm._v("\n    Aantal\n  ")]
      ),
      _vm._v(" "),
      _c("th", { class: _vm.getPriceStyle(), attrs: { scope: "col" } }, [
        _vm._v("\n    Bedrag\n  "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }