var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        "is-active": _vm.isActive,
        "on-close": _vm.onClose,
        "show-cancel-button": true,
      },
    },
    [
      _c("div", { staticClass: "flex space-x-3 items-center h-1/2" }, [
        _c("h1", { staticClass: "text-xl font-semibold" }, [
          _vm._v("Gebruikerinformatie"),
        ]),
      ]),
      _vm._v(" "),
      _vm.user
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-col mt-5 sm:text-base text-sm space-y-3 ml-3",
            },
            [
              _c("div", { staticClass: "flex flex-col" }, [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("Naam: "),
                ]),
                _vm._v(" "),
                _c("span", {}, [_vm._v(_vm._s(_vm.user.getFullName()))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col" }, [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("E-mailadres: "),
                ]),
                _vm._v(" "),
                _c("span", {}, [_vm._v(_vm._s(_vm.user.email))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col" }, [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("Telefoonnummer: "),
                ]),
                _vm._v(" "),
                _c("span", {}, [_vm._v(_vm._s(_vm.user.telephoneNumber))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col" }, [
                _c("span", { staticClass: "font-semibold" }, [
                  _vm._v("Adres: "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-col" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.user.street + " " + _vm.user.houseNumber)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.user.postalCode + " " + _vm.user.city)),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }