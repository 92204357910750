var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class:
        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 font-medium text-white focus:outline-none text-sm " +
        _vm.getColor(),
      attrs: { type: "button" },
      on: { click: _vm.onClick },
    },
    [_vm._v("\n  " + _vm._s(_vm.content) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }