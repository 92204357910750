var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "w-full border-t border-gray-200 bg-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "container flex flex-wrap items-center justify-center px-4 py-8 mx-auto lg:justify-between",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "flex justify-center mt-4 lg:mt-0" }, [
            _c("a", { attrs: { href: _vm.facebookLink } }, [
              _c("span", {
                staticClass: "iconify h-8 w-8",
                attrs: {
                  "data-icon": "ant-design:facebook-outlined",
                  "data-inline": "false",
                },
              }),
            ]),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.instagramLink } }, [
              _c("span", {
                staticClass: "iconify h-8 w-8",
                attrs: {
                  "data-icon": "ant-design:instagram-outlined",
                  "data-inline": "false",
                },
              }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex flex-wrap justify-center" }, [
      _c("ul", { staticClass: "flex items-center space-x-4" }, [
        _c("li", [_c("a", { attrs: { href: "/" } }, [_vm._v("Home")])]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/about" } }, [_vm._v("Over ons")]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/ageselection" } }, [_vm._v("Bestellen")]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/contact" } }, [_vm._v("Contacteer ons")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }