<template>
  <div class="relative">
    <div class="" @click="() => onClick()">
      <span
          class="iconify h-6 w-6 md:h-8 md:w-8 text-gray-500 hover:text-gray-400 duration-200 cursor-pointer"
          data-icon="line-md:account">
      </span>
    </div>
    <div v-if="showDropdown" v-click-outside="onClickOutside"
         class="absolute origin-top-right top-5 right-2 p-1 bg-white rounded-md w-48 shadow-md z-50">
      <div class="text-gray-700 text-sm rounded-md hover:bg-gray-100 cursor-pointer w-full px-4 py-2"
           @click="() => navigateTo('/orders')">
        Bestellingen
      </div>
      <div class="text-gray-700 text-sm rounded-md hover:bg-gray-100 cursor-pointer w-full px-4 py-2 mb-1"
           @click="() => navigateTo('/profile')">
        Mijn informatie
      </div>
      <divider></divider>
      <div @click="logout"
           class="text-gray-700 text-sm rounded-md hover:bg-gray-100 cursor-pointer w-full px-4 py-2 text-red-800 mt-1">
        Uitloggen
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: "AccountIcon",
  props: {
    user: Object,
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  methods: {
    onClickOutside() {
      this.showDropdown = false;
    },
    onClick() {
      if (this.user.id) {
        this.showDropdown = !this.showDropdown;
      } else {
        window.location.href = '/login';
      }
    },
    logout() {
      window.location.href = '/logout';
    },
    navigateTo(url) {
      window.location.href = url;
    }
  }
}
</script>

<style scoped>

</style>
