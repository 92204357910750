<template>
  <nav class="bg-primary">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              @click="toggleNavBar()"
              aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
            <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch">
          <div class="flex-shrink-0 flex items-center">
            <img class="absolute left-10 top-3 h-16 lg:h-20 xl:h-24 w-auto lg:inline hidden"
                 src="/photos/logo.png" alt="logo">
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-5 md:space-x-8">
              <horizontal-nav-bar-item :is-active="currentUrl === '/'" name="Home" link="/"/>
              <horizontal-nav-bar-item :is-active="currentUrl === 'about'" name="Over ons" link="/about"/>
              <horizontal-nav-bar-item :is-active="currentUrl === 'ageselection'" name="Bestellen"
                                       link="/ageselection"/>
              <horizontal-nav-bar-item :is-active="currentUrl === 'contact'" name="Contacteer ons" link="/contact"/>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <div class="flex flex-row items-center space-x-2">
            <account-icon :user="user"></account-icon>
            <cart-icon :amount="cartAmount"></cart-icon>
          </div>
        </div>
      </div>
    </div>

    <mobile-nav-item-list
        :isCollapsed="navbarIsCollapsed"
        :current-url="currentUrl"
    />
  </nav>

</template>

<script>
import NavBarDropDown from "../NavBarDropDown";
import HorizontalNavBarItem from "./HorizontalNavBarItem";
import {ShoppingCartService} from "../../../services/ShoppingCartService";
import {mapState} from "vuex";

export default {
  name: "ShopHorizontalNavBar",
  components: {HorizontalNavBarItem, NavBarDropDown},
  props: {
    currentUrl: String,
  },
  data() {
    return {
      navbarIsCollapsed: true,
      shoppingCartService: new ShoppingCartService(),
      toggleNavBar() {
        this.navbarIsCollapsed = !this.navbarIsCollapsed;
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      cart: state => state.cart,
    }),
    cartAmount() {
      let amount = 0;

      this.cart.forEach((cartItem => {
        amount += parseInt(cartItem.amount);
      }));

      return amount;
    },
  },
}
</script>

<style scoped>

</style>
