var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        "is-active": _vm.isActive,
        "on-close": _vm.onClose,
        "show-cancel-button": "",
        "submit-button": { content: "Aanpassen", onClick: _vm.submit },
      },
    },
    [
      _c("div", { staticClass: "flex flex-col" }, [
        _c("h1", { staticClass: "font-poppins font-bold text-lg" }, [
          _vm._v("Wachtwoord wijzigen"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 space-y-5" }, [
          _c("div", { staticClass: "space-y-5 mb-3" }, [
            _c(
              "div",
              { staticClass: "flex w-full" },
              [
                _c("text-input", {
                  attrs: {
                    value: _vm.password,
                    label: "Huidig wachtwoord",
                    name: "password",
                    placeholder: "",
                    error: _vm.errors.password,
                    required: true,
                    type: "password",
                    "on-change": function (value) {
                      return (this$1.password = value)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "space-y-5 mb-3" }, [
            _c(
              "div",
              { staticClass: "flex w-full" },
              [
                _c("text-input", {
                  attrs: {
                    value: _vm.newPassword,
                    label: "Nieuw wachtwoord",
                    name: "newPassword",
                    placeholder: "",
                    error: _vm.errors.newPassword,
                    required: true,
                    type: "password",
                    "on-change": function (value) {
                      return (this$1.newPassword = value)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "space-y-5 mb-3" }, [
            _c(
              "div",
              { staticClass: "flex w-full" },
              [
                _c("text-input", {
                  attrs: {
                    value: _vm.checkPassword,
                    label: "Herhaal wachtwoord ter controle",
                    name: "checkPassword",
                    placeholder: "",
                    error: _vm.errors.checkPassword,
                    required: true,
                    type: "password",
                    "on-change": function (value) {
                      return (this$1.checkPassword = value)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }