var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "carousel",
    { attrs: { "per-page": 1, autoplay: "" } },
    _vm._l(_vm.images, function (image, index) {
      return _c("slide", { key: index }, [
        _c("div", {
          staticClass: "w-full bg-contain bg-center bg-no-repeat",
          style: _vm.getImageStyle(image),
        }),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }