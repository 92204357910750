var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-full bg-cover bg-center",
      style: _vm.getContainerStyle(),
    },
    [
      _c(
        "div",
        {
          class:
            "flex items-center justify-center h-full w-full bg-opacity-50 " +
            _vm.getOverlayColor(),
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c(
              "h1",
              {
                class:
                  "font-amatic text-6xl font-semibold uppercase md:text-6xl mb-6 " +
                  _vm.getTextColor(),
              },
              [_vm._v("\n        " + _vm._s(this.text) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.buttonText && _vm.buttonLink
              ? _c(
                  "a",
                  {
                    staticClass:
                      "px-8 py-4 bg-secondary text-base text-white font-medium font-poppins rounded hover:bg-secondary-hover focus:outline-none focus:bg-secondary-hover",
                    attrs: { href: this.buttonLink },
                  },
                  [_vm._v("\n        " + _vm._s(this.buttonText) + "\n      ")]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }