var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex rounded-xl border-2 border-gray-100 font-poppins text-sm",
    },
    [
      _c(
        "span",
        {
          class:
            "w-full rounded-l-xl px-3 py-2 text-center cursor-pointer " +
            _vm.getBackgroundColor(_vm.collect),
          on: {
            click: function () {
              return _vm.onChange(_vm.collect)
            },
          },
        },
        [_vm._v("\n    Afhalen\n    ")]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          class:
            "w-full rounded-r-xl px-3 py-2 text-center pointer cursor-pointer " +
            _vm.getBackgroundColor(_vm.delivery),
          on: {
            click: function () {
              return _vm.onChange(_vm.delivery)
            },
          },
        },
        [_vm._v("\n    Leveren\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }