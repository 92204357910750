var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        "is-active": _vm.error.active,
        "on-close": _vm.onClose,
        "show-cancel-button": "",
      },
    },
    [
      _c("div", { staticClass: "flex space-x-3 items-center h-1/2" }, [
        _c("span", {
          class: "iconify h-8 w-8 " + _vm.getIconColor(),
          attrs: { "data-icon": "mdi:warning" },
        }),
        _vm._v(" "),
        _c("h1", { staticClass: "text-xl font-semibold" }, [
          _vm._v("Opgelet!"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-5 w-full" }, [
        _c("p", [_vm._v(_vm._s(_vm.error.message))]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-5 text-sm text-gray-700" }, [
          _vm._v(
            "Een bericht werd verzonden naar de ontwikkelaar. Mocht je toch nog vragen of opmerkingen hebben, dan kan je ons contacteren."
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }