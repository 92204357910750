var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "flex bg-white rounded-full text-md " + _vm.getHeight() },
    [
      _c(
        "div",
        {
          staticClass:
            "flex justify-center items-center bg-tertiary hover:bg-tertiary-light rounded-tl-full rounded-bl-full text-gray-700 w-12 cursor-pointer",
          on: { click: _vm.onDecrement },
        },
        [
          _c("span", {
            staticClass: "iconify h-3 w-3",
            attrs: { "data-icon": "akar-icons:minus", "data-inline": "false" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex justify-center items-center border-tertiary border-t-4 border-b-4 text-gray-700 w-12 select-none",
        },
        [_c("p", [_vm._v(_vm._s(_vm.amount))])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex justify-center items-center bg-tertiary hover:bg-tertiary-light rounded-tr-full rounded-br-full text-gray-700 w-12 cursor-pointer",
          on: { click: _vm.onIncrement },
        },
        [
          _c("span", {
            staticClass: "iconify h-3 w-3",
            attrs: { "data-icon": "akar-icons:plus", "data-inline": "false" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }