var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paginationData
    ? _c(
        "div",
        { staticClass: "px-4 py-3 flex items-center justify-betweensm:px-6" },
        [
          _vm.paginationData.last_page > 1
            ? _c(
                "div",
                {
                  staticClass:
                    "flex-1 flex justify-between items-center sm:hidden",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
                      on: {
                        click: function ($event) {
                          return _vm.updatePageNumber(
                            _vm.paginationData.current_page - 1
                          )
                        },
                      },
                    },
                    [_vm._v("\n      Vorige\n    ")]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.paginationData.current_page +
                          "/" +
                          _vm.paginationData.last_page
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
                      on: {
                        click: function ($event) {
                          return _vm.updatePageNumber(
                            _vm.paginationData.current_page + 1
                          )
                        },
                      },
                    },
                    [_vm._v("\n      Volgende\n    ")]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "hidden sm:flex-1 sm:flex sm:items-center sm:justify-between",
            },
            [
              _c("div", [
                _c("p", { staticClass: "text-sm text-gray-700" }, [
                  _vm._v("\n        " + _vm._s(" ") + "\n        "),
                  _c("span", { staticClass: "font-medium" }, [
                    _vm._v(_vm._s(this.paginationData.from)),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(" ") +
                      "\n        tot\n        " +
                      _vm._s(" ") +
                      "\n        "
                  ),
                  _c("span", { staticClass: "font-medium" }, [
                    _vm._v(_vm._s(this.paginationData.to)),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(" ") +
                      "\n        van\n        " +
                      _vm._s(" ") +
                      "\n        "
                  ),
                  _c("span", { staticClass: "font-medium" }, [
                    _vm._v(_vm._s(this.paginationData.total)),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(" ") + "\n        weergegeven\n      "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.paginationData.last_page !== 1
                ? _c("div", [
                    _c(
                      "nav",
                      {
                        staticClass:
                          "relative z-0 inline-flex rounded-md shadow-sm -space-x-px",
                        attrs: { "aria-label": "Pagination" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.updatePageNumber(1)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Eerste"),
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "iconify h-5 w-5",
                              attrs: { "data-icon": "mdi:chevron-double-left" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.updatePageNumber(
                                  _vm.paginationData.current_page - 1
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Vorige"),
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "iconify h-5 w-5",
                              attrs: { "data-icon": "mdi:chevron-left" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.getRelevantPageNumbers(),
                          function (pageNumber) {
                            return _c(
                              "span",
                              {
                                class: _vm.getStyle(pageNumber),
                                on: {
                                  click: function ($event) {
                                    return _vm.updatePageNumber(pageNumber)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(pageNumber) +
                                    "\n        "
                                ),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.updatePageNumber(
                                  _vm.paginationData.current_page + 1
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Volgende"),
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "iconify h-5 w-5",
                              attrs: { "data-icon": "mdi:chevron-right" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.updatePageNumber(
                                  _vm.paginationData.last_page
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Laatste"),
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "iconify h-5 w-5",
                              attrs: {
                                "data-icon": "mdi:chevron-double-right",
                              },
                            }),
                          ]
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }