var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mt-5 md:w-1/2 lg:w-1/3" },
        [
          _c("delivery-selector", {
            attrs: {
              "selected-type": _vm.selectedDeliveryType,
              "on-change": function (value) {
                return _vm.changeDeliveryType(value)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedDeliveryType === _vm.delivery
        ? _c("delivery-address")
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedDeliveryType === _vm.collect
        ? _c("collection-address", {
            attrs: {
              "selected-location": _vm.selectedLocation,
              "on-change": function (location) {
                return _vm.changeLocation(location)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-8 w-full xs:w-1/3" },
        [
          _c("cta-button", {
            attrs: {
              variant: _vm.secondary,
              content: "Bestellen",
              "on-click": _vm.createOrder,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }