var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c("div", { staticClass: "font-poppins space-y-5 px-5 md:px-0" }, [
      _c("h1", { staticClass: "font-medium text-lg md:text-2xl text-center" }, [
        _vm._v("Jouw winkelwagentje is leeg..."),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-center text-sm md:text-md" }, [
        _vm._v(
          "Je kan onze producten bekijken in de webshop en daarna jouw winkelwagentje vullen"
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mx-auto w-1/2 md:w-3/12 lg:w-2/12" },
        [
          _c("cta-button", {
            attrs: {
              content: "Naar producten",
              variant: _vm.secondary,
              "on-click": _vm.toShop,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }