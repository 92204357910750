var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-20" }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-col px-2 md:px-10 md:flex-row w-full xl:w-3/4 mx-auto",
      },
      [
        _c(
          "div",
          { staticClass: "flex flex-col md:w-2/3 sm:w-full" },
          _vm._l(Object.keys(_vm.sortedCart), function (category) {
            return _c(
              "div",
              [
                _vm.sortedCart[category].length
                  ? _c("div", { staticClass: "w-full text-center mb-8" }, [
                      _c("h1", { staticClass: "font-poppins text-xl" }, [
                        _vm._v(_vm._s(_vm.parseCategoryToAges(category))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.sortedCart[category], function (cartItem) {
                  return _c(
                    "div",
                    { staticClass: "w-full mx-auto text-sm xl:text-md" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center bg-white rounded-xl shadow-sm w-full px-3 md:px-10 py-4 md:py-6 mb-5 space-x-3 font-poppins text-gray-700",
                        },
                        [
                          _c("span", { staticClass: "w-4/6" }, [
                            _vm._v(_vm._s(cartItem.product.name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-row-reverse items-center justify-between w-3/6 xl:w-2/6",
                            },
                            [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function () {
                                      return _vm.deleteCartItem(cartItem)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "iconify h-5 w-5 md:h-6 md:w-6 text-red-600 cursor-pointer hover:text-red-500",
                                    attrs: { "data-icon": "mdi:delete" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-center" }, [
                                _vm._v(
                                  "\n                 € " +
                                    _vm._s(_vm.getPriceOfCartItem(cartItem)) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "text-center" },
                                [
                                  _c("amount-selector", {
                                    attrs: {
                                      amount: cartItem.amount,
                                      "on-increment": function () {
                                        return _vm.onIncrement(cartItem)
                                      },
                                      "on-decrement": function () {
                                        return _vm.onDecrement(cartItem)
                                      },
                                      variant: _vm.smallVariant,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex flex-col w-1/3 bg-white px-5 py-3 h-1/3 ml-5 rounded-xl shadow-sm hidden md:block",
          },
          [
            _c("div", { staticClass: "flex flex-col text-sm" }, [
              _c("span", { staticClass: "text-xl mb-2" }, [_vm._v("Levering")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "space-y-1" },
                [
                  _c("cart-delivery-detail", {
                    attrs: { content: "Afhalen op twee locaties" },
                  }),
                  _vm._v(" "),
                  _c("cart-delivery-detail", {
                    attrs: {
                      content: "Thuis geleverd in een straal van 5 km voor € 5",
                    },
                  }),
                  _vm._v(" "),
                  _c("cart-delivery-detail", {
                    attrs: { content: "Nog info die hier kan?" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-3" }, [_c("divider")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-col mt-3" }, [
              _c(
                "div",
                { staticClass: "flex justify-between text-sm lg:text-base" },
                [
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v("Aantal: "),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.getTotalCount(_vm.cart)) + " potjes"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex justify-between mt-0.5 text-sm lg:text-base",
                },
                [
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v("Totaal: "),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("€ " + _vm._s(_vm.getTotalPrice(_vm.cart))),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mt-5 mb-1" },
                [
                  _c("cta-button", {
                    attrs: {
                      "on-click": _vm.onOrderClick,
                      content: "Bestellen",
                      variant: _vm.secondary,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex items-center px-3 fixed bottom-0 h-20 w-full md:hidden bg-white border-t-2 border-gray-300 rounded-t-lg",
      },
      [
        _c("div", { staticClass: "flex flex-col w-1/2 space-y-1" }, [
          _c("div", { staticClass: "flex text-center" }, [
            _c("span", { staticClass: "font-bold mr-2" }, [_vm._v("Totaal: ")]),
            _vm._v(" "),
            _c("span", [_vm._v("€ " + _vm._s(_vm.getTotalPrice(_vm.cart)))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex text-center" }, [
            _c("span", { staticClass: "font-bold mr-2" }, [_vm._v("Aantal: ")]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.getTotalCount(_vm.cart)) + " potjes"),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-1/2 text-center" },
          [
            _c("cta-button", {
              attrs: {
                "on-click": _vm.onOrderClick,
                content: "Bestellen",
                variant: _vm.secondary,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }