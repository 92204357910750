var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product != null
    ? _c(
        "div",
        {
          staticClass: "fixed z-10 inset-0 overflow-y-auto",
          attrs: {
            "aria-labelledby": "modal-title",
            role: "dialog",
            "aria-modal": "true",
          },
          on: { click: _vm.close },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-end justify-center min-h-screen pt-4 sm:px-4 px-2 pb-20 text-center sm:block sm:p-0",
            },
            [
              _c("div", {
                staticClass:
                  "fixed inset-0 bg-black bg-opacity-50 transition-opacity",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "hidden sm:inline-block sm:align-middle sm:h-screen",
                  attrs: { "aria-hidden": "true" },
                },
                [_vm._v("​")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-11/12 w-full font-poppins",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" },
                    [
                      _c(
                        "h1",
                        { staticClass: "text-lg sm:text-xl font-semibold" },
                        [_vm._v("Toevoegen aan winkelmandje")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex space-x-1 mt-2 text-sm mb-6" },
                        [
                          _vm.product.package
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center bg-secondary sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8",
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.product.name))])]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.product.package
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center bg-primary-dark sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.product.contentAmount) +
                                        " potjes"
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.product.package
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center bg-primary-dark sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.product.weight) + " g"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-center bg-primary-dark sm:rounded-full rounded-2xl text-white p-1 md:w-2/12 w-3/12 md:h-full h-8",
                            },
                            [
                              _c("span", [
                                _vm._v("€ " + _vm._s(_vm.productPrice)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-full px-5 mt-5" }, [
                        _c(
                          "ul",
                          { staticClass: "list-disc mb-3 h-4/5 space-y-3" },
                          _vm._l(_vm.product.contents, function (content) {
                            return _c("li", [_vm._v(_vm._s(content))])
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex items-center mt-5 space-x-5" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-semibold text-center md:w-2/12 w-3/12",
                            },
                            [_vm._v("€ " + _vm._s(_vm.total))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "md:w-2/12 w-3/12" },
                            [
                              _c("amount-selector", {
                                attrs: {
                                  amount: _vm.amount,
                                  "on-increment": _vm.incrementAmount,
                                  "on-decrement": _vm.decrementAmount,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-secondary-hover sm:ml-3 sm:w-auto sm:text-sm select-none",
                          attrs: { type: "button" },
                          on: { click: _vm.addToCart },
                        },
                        [_vm._v("\n          Toevoegen\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "mt-3 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm select-none",
                          attrs: { type: "button" },
                          on: { click: _vm.close },
                        },
                        [_vm._v("\n          Annuleren\n        ")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }