var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "px-3 font-poppins font-sm w-full" }, [
    _c(
      "label",
      {
        class: "block text-gray-700 mb-2 " + _vm.getLabelErrorStyle(),
        attrs: { for: "input" + _vm.name },
      },
      [_vm._v("\n    " + _vm._s(this.label) + "\n  ")]
    ),
    _vm._v(" "),
    _c("input", {
      class:
        "bg-gray-100 appearance-none block w-full rounded py-3 px-4 outline-none " +
        _vm.getInputErrorStyle(),
      attrs: {
        id: "input" + _vm.name,
        name: _vm.name,
        placeholder: _vm.placeholder,
        type: _vm.inputType,
        required: _vm.required,
      },
      domProps: { value: _vm.value },
      on: {
        blur: function (event) {
          return _vm.onChange(event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.error
      ? _c("p", { staticClass: "text-xs text-red-600 mt-2" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }