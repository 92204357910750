var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "container mx-auto flex flex-col font-poppins text-gray-800 text-md lg:text-lg mt-10 text-center",
    },
    [
      _c("h1", { staticClass: "text-4xl lg:text-5xl font-amatic font-bold" }, [
        _vm._v(_vm._s(this.title)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-3" }, [_vm._v(_vm._s(this.subText))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }