var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "bg-primary" },
    [
      _c("div", { staticClass: "max-w-7xl mx-auto px-2 sm:px-6 lg:px-8" }, [
        _c(
          "div",
          { staticClass: "relative flex items-center justify-between h-16" },
          [
            _c(
              "div",
              {
                staticClass:
                  "absolute inset-y-0 left-0 flex items-center sm:hidden",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
                    attrs: { "aria-expanded": "false" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleNavBar()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Open main menu"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "block h-6 w-6",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M4 6h16M4 12h16M4 18h16",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "hidden h-6 w-6",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M6 18L18 6M6 6l12 12",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex-1 flex items-center justify-center sm:items-stretch",
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "hidden sm:block sm:ml-6" }, [
                  _c(
                    "div",
                    { staticClass: "flex space-x-5 md:space-x-8" },
                    [
                      _c("horizontal-nav-bar-item", {
                        attrs: {
                          "is-active": _vm.currentUrl === "/",
                          name: "Home",
                          link: "/",
                        },
                      }),
                      _vm._v(" "),
                      _c("horizontal-nav-bar-item", {
                        attrs: {
                          "is-active": _vm.currentUrl === "about",
                          name: "Over ons",
                          link: "/about",
                        },
                      }),
                      _vm._v(" "),
                      _c("horizontal-nav-bar-item", {
                        attrs: {
                          "is-active": _vm.currentUrl === "ageselection",
                          name: "Bestellen",
                          link: "/ageselection",
                        },
                      }),
                      _vm._v(" "),
                      _c("horizontal-nav-bar-item", {
                        attrs: {
                          "is-active": _vm.currentUrl === "contact",
                          name: "Contacteer ons",
                          link: "/contact",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-row items-center space-x-2" },
                  [
                    _c("account-icon", { attrs: { user: _vm.user } }),
                    _vm._v(" "),
                    _c("cart-icon", { attrs: { amount: _vm.cartAmount } }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("mobile-nav-item-list", {
        attrs: {
          isCollapsed: _vm.navbarIsCollapsed,
          "current-url": _vm.currentUrl,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-shrink-0 flex items-center" }, [
      _c("img", {
        staticClass:
          "absolute left-10 top-3 h-16 lg:h-20 xl:h-24 w-auto lg:inline hidden",
        attrs: { src: "/photos/logo.png", alt: "logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }