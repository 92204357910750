var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("address-modal", {
        attrs: {
          "is-active": _vm.addressModalIsActive,
          "on-close": _vm.closeAddressModal,
          user: _vm.user,
        },
      }),
      _vm._v(" "),
      _c("password-modal", {
        attrs: {
          "is-active": _vm.passwordModalIsActive,
          "on-close": _vm.closePasswordModal,
        },
      }),
      _vm._v(" "),
      _vm.user
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-col mx-auto mt-10 w-2/3 bg-white shadow-md px-10 py-8 rounded-md space-y-2",
            },
            [
              _c("div", [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-col p-4 space-y-2" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.user.firstName) +
                        " " +
                        _vm._s(_vm.user.lastName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.user.email))]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.user.telephoneNumber))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "flex items-center space-x-2" }, [
                  _c("h1", { staticClass: "text-xl font-semibold" }, [
                    _vm._v("Adres"),
                  ]),
                  _vm._v(" "),
                  _c("span", { on: { click: _vm.openAddressModal } }, [
                    _c("em", {
                      staticClass:
                        "iconify h-6 w-6 text-blue-600 cursor-pointer",
                      attrs: { "data-icon": "uil:edit" },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-col p-4 space-y-2" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.user.street) +
                        " " +
                        _vm._s(_vm.user.houseNumber)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.user.postalCode) + " " + _vm._s(_vm.user.city)
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("h1", { staticClass: "text-xl font-semibold" }, [
                  _vm._v("Wachtwoord"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-1/3 mt-5" },
                  [
                    _c("cta-button", {
                      attrs: {
                        content: "Wachtwoord aanpassen",
                        variant: _vm.dangerVariant,
                        "on-click": _vm.openPasswordModal,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center space-x-2" }, [
      _c("h1", { staticClass: "text-xl font-semibold" }, [_vm._v("Gegevens")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }