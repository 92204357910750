var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.getStyling() }, [
    _c(
      "div",
      { staticClass: "px-2 pt-2 pb-3 space-y-1" },
      [
        _c("horizontal-nav-bar-item", {
          attrs: {
            "is-active": _vm.currentUrl === "/",
            name: "Home",
            link: "/",
            "is-mobile": true,
          },
        }),
        _vm._v(" "),
        _c("horizontal-nav-bar-item", {
          attrs: {
            "is-active": _vm.currentUrl === "about",
            name: "Over ons",
            link: "/about",
            "is-mobile": true,
          },
        }),
        _vm._v(" "),
        _c("horizontal-nav-bar-item", {
          attrs: {
            "is-active": _vm.currentUrl === "ageselection",
            name: "Bestellen",
            link: "/ageselection",
            "is-mobile": true,
          },
        }),
        _vm._v(" "),
        _c("horizontal-nav-bar-item", {
          attrs: {
            "is-active": _vm.currentUrl === "contact",
            name: "Contacteer ons",
            link: "/contact",
            "is-mobile": true,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }