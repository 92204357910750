import {parseToCommaSeparated} from "../helpers/StringHelper";
import moment from "moment";
import {OrderItem} from "./OrderItem";
import {User} from "./User";

export class Order {
    constructor() {
        this.id = 0;
        this.total = 0;
        this.deliveryType = '';
        this.collectionLocation = '';
        this.date = '';
        this.items = [];
        this.user = null;
    }

    parse(data) {
        this.id = data.id;
        this.total = parseToCommaSeparated(data.total);
        this.deliveryType = data.delivery_type;
        this.collectionLocation = data.collection_location;
        this.date = moment(data.created_at).format("DD/MM/YYYY");
        this.items = this.parseItems(data.order_items);

        if (data.user) {
            let user = new User();
            user.parse(data.user);

            this.user = user;
        }
    }

    getProductAmount() {
        let productCount = 0;

        this.items.forEach(item => {
            productCount += item.amount;
        });

        return productCount;
    }

    getTotalPrice() {
        let price = 0;

        this.items.forEach(item => {
            price += item.price * item.amount;
        });

        return parseToCommaSeparated(price, 2);
    }

    parseItems(productsData) {
        if (!productsData) return [];

        return productsData.map(productData => {
            let item = new OrderItem();
            item.parse(productData);

            return item;
        });
    }
}
