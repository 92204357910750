var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c("div", { staticClass: "-my-2 overflow-x-auto" }, [
      _c(
        "div",
        { staticClass: "py-2 align-middle inline-block px-2 w-full text-sm" },
        [
          _c(
            "div",
            {
              staticClass:
                "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg",
            },
            [
              _c(
                "table",
                { staticClass: "w-full divide-y divide-gray-200" },
                [
                  _c("orders-table-header"),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { staticClass: "bg-white divide-y divide-gray-200" },
                    _vm._l(_vm.orders, function (order) {
                      return _c("orders-table-item", {
                        key: order.id,
                        attrs: {
                          order: order,
                          "on-order-click": function () {
                            return _vm.onOrderClick(order)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }