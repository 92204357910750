var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex space-x-2" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "w-11/12 lg:w-10/12" }, [
      _c("span", { staticClass: "text-md" }, [_vm._v(_vm._s(_vm.content))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: " w-1/12 lg:2/12" }, [
      _c("span", {
        staticClass: "iconify text-green-600 font-bold h-6 w-6",
        attrs: { "data-icon": "mdi-check-circle" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }