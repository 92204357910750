<template>
  <div class="w-full h-1/3 py-6">
    <div
        @click="() => navigate()"
        class="mx-auto grid items-center justify-items-center bg-secondary-light hover:bg-secondary rounded-full text-gray-800 hover:text-white text-lg lg:text-xl w-24 h-24 lg:w-32 lg:h-32 cursor-pointer">
      <p>{{ this.age }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "AgeSelectBox",
  props: {
    age: String,
    link: String,
  },
  data() {
    return {
      navigate() {
        window.location.href = this.link;
      }
    }
  },
}
</script>

<style scoped>

</style>
