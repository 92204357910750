var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full h-1/3 py-6" }, [
    _c(
      "div",
      {
        staticClass:
          "mx-auto grid items-center justify-items-center bg-secondary-light hover:bg-secondary rounded-full text-gray-800 hover:text-white text-lg lg:text-xl w-24 h-24 lg:w-32 lg:h-32 cursor-pointer",
        on: {
          click: function () {
            return _vm.navigate()
          },
        },
      },
      [_c("p", [_vm._v(_vm._s(this.age))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }