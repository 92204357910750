var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col font-poppins w-full h-full text-white shadow-xl sm:rounded-b-2xl",
    },
    [
      _c(
        "div",
        { class: "w-full h-1/3 sm:rounded-t-2xl px-5 py-5 " + _vm.getColor() },
        [
          _c("div", { staticClass: "flex flex-col" }, [
            _c("h1", { staticClass: "font-semibold text-xl mb-5" }, [
              _vm._v(_vm._s(this.title)),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(this.street + " " + this.number))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(this.postalCode + " " + this.city))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s("Tel: " + this.telephone))]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "flex flex-row items-center bg-white shadow-lg rounded-lg xl:w-1/3 lg:w-1/2 sm:w-1/3 xs:w-1/2 w-full xl:p-1 p-3 h-10 mt-5 focus:outline-none hover:bg-gray-50",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.goToMaps()
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "iconify text-3xl text-blue-600 w-1/6",
                  attrs: {
                    "data-icon": "uil:directions",
                    "data-inline": "false",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "text-gray-700 font-semibold w-5/6" },
                  [_vm._v("Routebeschrijving")]
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "w-full h-2/3 bg-cover bg-center sm:rounded-b-2xl cursor-pointer",
        style: _vm.getBackgroundStyle(),
        on: {
          click: function ($event) {
            return _vm.goToMaps()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }