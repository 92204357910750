var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col sm:flex-row text-xs sm:space-x-5" },
    [
      _c("address-info-item", {
        attrs: {
          "row-a-content": "Charlotte",
          "row-b-content": "Naamsesteenweg 377a",
          "row-c-content": "3800 - Sint-Truiden",
          "on-click": function () {
            return _vm.onChange(_vm.charlotte)
          },
          variant: _vm.collectionVariant,
          selected: _vm.selectedLocation === _vm.charlotte,
        },
      }),
      _vm._v(" "),
      _c("address-info-item", {
        attrs: {
          "row-a-content": "Jolien",
          "row-b-content": "E.Moyaertslaan 19",
          "row-c-content": "3400 - Landen",
          "on-click": function () {
            return _vm.onChange(_vm.jolien)
          },
          variant: _vm.collectionVariant,
          selected: _vm.selectedLocation === _vm.jolien,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }