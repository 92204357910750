<template>
  <div>
    <div class="flex flex-row flex-row-reverse items-center relative p-0.5 rounded-xl cursor-pointer"
         @click="() => onCartClick()">
      <span
          class="iconify h-6 w-6 md:h-8 md:w-8 text-gray-500 hover:text-gray-400 duration-200"
          data-icon="mdi:shopping-cart"
          data-inline="false">
      </span>
      <span v-if="showPrice" class="mr-1 text-gray-700 text-sm font-poppins">€ {{ totalPrice }}</span>
      <span
          v-if="user && user.id && amount"
          class="rounded-full bg-secondary w-4 h-4 absolute top-0 right-0 text-white text-xs font-bold text-center select-none">
            {{ this.amount }}
      </span>
    </div>
    <div v-if="showDropdown" v-click-outside="onClickOutside"
         class="absolute top-15 right-0 px-4 py-2 bg-white rounded-sm shadow-md z-10 w-2/3 lg:w-1/2">
      <h1 class="font-poppins font-semibold text-md my-4">Winkelwagen</h1>
      <div v-for="cartItem in cart" class="flex cursor-default mb-3 w-full space-x-2 md:space-x-5">
        <div class="text-sm text-gray-700 w-1/12 text-center">
          {{ `${cartItem.amount}x` }}
        </div>
        <div class="text-sm text-gray-700 w-10/12">
          {{ cartItem.product.name }}
        </div>
        <div class="font-bold text-sm w-2/12 text-center">
          € {{ parsePrice(cartItem.amount * cartItem.product.price) }}
        </div>
      </div>

      <div class="mt-5 mb-5">
        <divider></divider>
      </div>

      <div class="flex flex-row-reverse mb-5 text-sm">
        <div class="font-bold text-sm w-2/12 text-center ml-3">€ {{ totalPrice }}</div>
        <span class="">Totaalbedrag: </span>
      </div>

      <div class="flex flex-row-reverse items-center mb-2">
        <span class="w-1/4">
          <cta-button content="Bestellen" :variant="orderButtonVariant" :on-click="toCartPage"></cta-button>
        </span>
        <span @click="toCartPage" class="text-sm text-blue-600 underline mr-3 cursor-pointer">Wijzig winkelmandje</span>
      </div>
    </div>

  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import {mapState} from "vuex";
import {parseToCommaSeparated} from "../../../helpers/StringHelper";
import {SECONDARY} from "../../../constants/Variants";

export default {
  name: "CartIcon",
  props: {
    amount: Number,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showDropdown: false,
      orderButtonVariant: SECONDARY
    }
  },
  methods: {
    toggleDropdown() {
      // sm breakpoint
      if (window.innerWidth < 640) {
        this.toCartPage();
      } else {
        this.showDropdown = !this.showDropdown;
      }
    },
    onClickOutside(event) {
      this.showDropdown = false;
    },
    parsePrice(price) {
      return parseToCommaSeparated(price, 2);
    },
    toCartPage() {
      window.location.href = '/cart/overview';
    },
    onCartClick() {
      if (this.user.id) {
        this.toggleDropdown();
      } else {
        window.location.href = '/login';
      }
    },
    calculateTotalCartPrice() {
      let total = 0;

      this.cart.forEach(cartItem => {
        total += (cartItem.product.price * cartItem.amount);
      });

      return total;
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart,
      user: state => state.user
    }),
    totalPrice() {
      return parseToCommaSeparated(this.calculateTotalCartPrice(), 2);
    },
    showPrice() {
      const totalPrice = this.calculateTotalCartPrice();

      return totalPrice > 0;
    }
  },
}
</script>

<style scoped>

</style>
