var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ml-3 relative" }, [
    _vm._m(0),
    _vm._v(" "),
    !_vm.isCollapsed
      ? _c(
          "div",
          {
            staticClass:
              "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5",
            attrs: {
              role: "menu",
              "aria-orientation": "vertical",
              "aria-labelledby": "user-menu",
            },
          },
          [
            _c(
              "a",
              {
                staticClass:
                  "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                attrs: { href: "#", role: "menuitem" },
              },
              [_vm._v("Your Profile")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                attrs: { href: "#", role: "menuitem" },
              },
              [_vm._v("Settings")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                attrs: { href: "#", role: "menuitem" },
              },
              [_vm._v("Sign out")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white",
          attrs: { id: "user-menu", "aria-haspopup": "false" },
        },
        [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Open user menu")]),
          _vm._v(" "),
          _c("img", {
            staticClass: "h-8 w-8 rounded-full",
            attrs: {
              src: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
              alt: "",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }