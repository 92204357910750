var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        on: {
          click: function () {
            return _vm.onClick()
          },
        },
      },
      [
        _c("span", {
          staticClass:
            "iconify h-6 w-6 md:h-8 md:w-8 text-gray-500 hover:text-gray-400 duration-200 cursor-pointer",
          attrs: { "data-icon": "line-md:account" },
        }),
      ]
    ),
    _vm._v(" "),
    _vm.showDropdown
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.onClickOutside,
                expression: "onClickOutside",
              },
            ],
            staticClass:
              "absolute origin-top-right top-5 right-2 p-1 bg-white rounded-md w-48 shadow-md z-50",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-gray-700 text-sm rounded-md hover:bg-gray-100 cursor-pointer w-full px-4 py-2",
                on: {
                  click: function () {
                    return _vm.navigateTo("/orders")
                  },
                },
              },
              [_vm._v("\n      Bestellingen\n    ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "text-gray-700 text-sm rounded-md hover:bg-gray-100 cursor-pointer w-full px-4 py-2 mb-1",
                on: {
                  click: function () {
                    return _vm.navigateTo("/profile")
                  },
                },
              },
              [_vm._v("\n      Mijn informatie\n    ")]
            ),
            _vm._v(" "),
            _c("divider"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "text-gray-700 text-sm rounded-md hover:bg-gray-100 cursor-pointer w-full px-4 py-2 text-red-800 mt-1",
                on: { click: _vm.logout },
              },
              [_vm._v("\n      Uitloggen\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }