var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto relative" },
    [
      _c("header-title", { attrs: { title: _vm.getTitle() } }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("add-to-cart-modal"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c("underlined-title", { attrs: { variant: _vm.PRIMARY } }, [
            _vm._v("Boxen"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "my-10 grid gap-x-10 gap-y-10 sm:grid-cols-2 xl:grid-cols-5",
            },
            _vm._l(_vm.packages, function (box) {
              return _c("product-card", {
                key: box.id,
                attrs: { product: box },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("underlined-title", { attrs: { variant: _vm.PRIMARY } }, [
        _vm._v("Individuele potjes"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "my-10 grid gap-x-10 gap-y-10 sm:grid-cols-2 xl:grid-cols-5",
        },
        _vm._l(_vm.products, function (product) {
          return _c("product-card", {
            key: product.id,
            attrs: { product: product },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex justify-center w-full mt-5" }, [
      _c("p", { staticClass: "font-poppins" }, [
        _vm._v(
          "Hier staat dan nog wat meer info, bijvoorbeeld het gewicht van alle potjes"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }