var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c("div", { staticClass: "-my-2" }, [
      _c("div", { staticClass: "py-2 inline-block w-full px-2 sm:px-6" }, [
        _c(
          "div",
          {
            staticClass:
              "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg",
          },
          [
            _c(
              "table",
              {
                staticClass:
                  "w-full divide-y divide-gray-200 table-fixed sm:table-auto",
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  {
                    staticClass:
                      "bg-white divide-y divide-gray-200 text-xs sm:text-sm",
                  },
                  [
                    _vm._l(_vm.order.items, function (item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", { staticClass: "px-2 py-2 sm:px-6 sm:py-4" }, [
                          _c("div", { staticClass: "flex items-center" }, [
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "div",
                                { staticClass: "font-medium text-gray-900" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left hidden sm:table-cell",
                          },
                          [
                            _c("div", { staticClass: "text-gray-900" }, [
                              _vm._v(_vm._s(item.category)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left sm:hidden table-cell",
                          },
                          [
                            _c("div", { staticClass: "text-gray-900" }, [
                              _vm._v(_vm._s(_vm.shortenCategoryText(item))),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left text-center",
                          },
                          [
                            _c("div", { staticClass: "text-gray-900" }, [
                              _vm._v(_vm._s(item.amount)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left text-gray-700 font-bold",
                          },
                          [
                            _vm._v(
                              "€ " +
                                _vm._s(_vm.getTotalPrice(item)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", { staticClass: "bg-gray-50" }, [
                      _c("td"),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "px-6 py-4 whitespace-nowrap text-sm text-gray-700",
                        },
                        [
                          _c("div", { staticClass: "flex flex-col" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-light text-xs hidden sm:block",
                              },
                              [_vm._v("Totaal aantal: ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-bold text-xs sm:text-base" },
                              [_vm._v(_vm._s(_vm.order.getProductAmount()))]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "px-6 py-2 whitespace-nowrap text-sm text-gray-700",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col h-full items-center text-left",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-light text-xs hidden sm:block",
                                },
                                [_vm._v("Totaal: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "font-bold text-xs sm:text-base",
                                },
                                [
                                  _vm._v(
                                    "€ " + _vm._s(_vm.order.getTotalPrice())
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "bg-gray-50" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticClass:
              "px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-2/6 sm:w-auto",
            attrs: { scope: "col" },
          },
          [_vm._v("\n              Naam\n            ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-1/6 sm:w-auto",
            attrs: { scope: "col" },
          },
          [_vm._v("\n              Category\n            ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-1/6 sm:w-auto",
            attrs: { scope: "col" },
          },
          [_vm._v("\n              Aantal\n            ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-1/6 sm:w-auto",
            attrs: { scope: "col" },
          },
          [_vm._v("\n              Prijs\n            ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }